import React, { useEffect, useState } from "react";
import "./userlist.css";
import { FiEdit } from "react-icons/fi";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "../../../axios";
import ReactPaginate from "react-paginate";
import PopUp from "./PopUp";
import { toast } from "react-toastify";

function UserList() {
  const { t } = useTranslation();

  const [userDetails, setUserDetails] = useState([]);

  const [pageCount, setPageCount] = useState(1);
  const [isLoaded, setisLoaded] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const getToken = localStorage.getItem("token");
  const parseToken = JSON.parse(getToken);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  const fetchUsers = async (pageToGet) => {
    // previously we used the config file fot the url now i am using Axios base url

    await axios
      .get(`/user?page=${pageToGet}`, {
        headers: {
          Authorization: "Bearer " + parseToken,
        },
      })
      .then((body) => {
        setUserDetails(body.data.users);
        setPageCount(body.data.count / 10);
        setisLoaded(true);
      })
      .catch((error) => console.error("Error", error));
  };

  //  reduces sentence
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  const handlePageChange = (selectedObject) => {
    let intSelectedPage = parseInt(selectedObject.selected) + 1;
    setCurrentPage(intSelectedPage);
    fetchUsers(intSelectedPage);
  };

  const deleteUser = async (id) => {
    try {
      await axios.delete(`/user?id=${id}`, {
        headers: {
          Authorization: "Bearer " + parseToken,
        },
      });
      fetchUsers(currentPage);
      toast.success("User deleted successfully.");
    } catch (e) {
      const message = e.response?.data?.error;
      console.log(message);
      toast.error(message);
    }
  };

  return (
    <div className="userlist">
      <div className="userlist_wrapper">
        <div className="userlist_title">
          <Link to="/" style={{ textDecoration: "none" }}>
            <IoIosArrowRoundBack className="back_icon" />
          </Link>
          {t("main.usersList")}
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>{t("main.firstName")}</th>
              <th>{t("main.lastName")}</th>
              <th>{t("main.email")}</th>
              <th>{t("main.status")}</th>
              <th>{t("main.operation")}</th>
            </tr>
          </thead>
          <tbody>
            {userDetails?.map((user, index) => (
              <tr key={user._id}>
                <td data-label="#">{index + (currentPage - 1) * 10 + 1}</td>

                <td data-label={`${t("main.firstName")}`}>{user?.firstName}</td>

                <td data-label={`${t("main.lastName")}`}>{user?.lastName}</td>

                <td data-label={`${t("main.email")}`}>
                  {truncate(user?.email, 18)}
                </td>

                <td data-label={`${t("main.status")}`}>
                  {user?.approved === true
                    ? `${t("main.approved")}`
                    : `${t("main.notApproved")}`}
                </td>

                <td data-label={`${t("main.operation")}`}>
                  <div className="icons_table">
                    <Link
                      to={`account-details/${user?._id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <FiEdit className="editIcon" />
                    </Link>
                    <PopUp deleteUser={deleteUser} _id={user?._id} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="pagination_wrapper">
          {isLoaded ? (
            <ReactPaginate
              previousLabel={`${t("main.previous")}`}
              nextLabel={`${t("main.next")}`}
              pageCount={pageCount}
              onPageChange={handlePageChange}
              containerClassName={"pagination_btn"}
              previousClassName={"previousBtn"}
              activeClassName={"pagination_btn_active"}
            />
          ) : (
            <div>{t("main.noResultsToDisplay")}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserList;
